<template>
  <div  id="container" v-if="true">
    <el-watermark :content="'青岛升平电气有限公司' + company.alias + (company.alias ? '分公司' : '')" :font="font" >
      <div :style="{'height': height + 'px','padding-top' : ((height - 378) / 2) + 'px' }"  class="login-bg" v-if="!loginSuccess">
        <el-row>
          <el-col :xs="2" :sm="7" :md="8"></el-col>
          <el-col :xs="20" :sm="10" :md="8">
            <el-card style="margin-top: 10px">
              <template v-slot:header >
                <h3 style="text-align: center;letter-spacing: 3px">
                  <el-tag v-if="false" disable-transitions type="success" size="large" effect="plain">{{company.name === '青岛升平电气有限公司' ? '' : company.name}}用户登录</el-tag>
                  <el-text style="font-size: 16px">用户登录</el-text>
                </h3>
              </template>
              <LoginForm :form-data="loginInfo" :loading="loading" @login="onLogin"/>
              <template #footer>
                <p style="text-align: center;letter-spacing: 1px;color: #999;font-size: 13px;height: 60px; line-height: 30px">
                  <span style="font-size: 15px">发票申请开具模块V1.0</span><br/>
                  <span>Copyright © 2023 青岛升平电气有限公司</span>
                </p>
              </template>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div v-else style="height: 100vh; width: 100vw;">
        <div style="height: 40px">
          <el-affix :offset="0">
            <div style="height: 40px;line-height: 40px;background-color: rgb(30, 159, 255);">
              <img src="./assets/images/company.png" style="height: 30px; width: 30px;margin: 5px 10px;float: left">
              <h2 style="float:left;font-size: 15px;letter-spacing: 2px;color: #F5F7FA">
                <span >发票申请开具模块V1.0</span>
              </h2>
              <p v-if="user.permissions > 1" style="float: right; margin-right: 15px;">
                <el-dropdown trigger="click">
                  <el-button type="primary"  style="height: 40px;line-height: 40px">
                    操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item  @click="onLogout" >登出</el-dropdown-item>
                      <el-dropdown-item @click="dialogs.apply_invoice = true"  v-if="(user.permissions & 0b1) != 0">发票申请</el-dropdown-item>
                      <el-dropdown-item @click="dialogs.export = true">数据导出</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </p>
              <el-button style="float: right; width: 60px;margin-top: 4px;margin-right: 5px" round type="primary" @click="searchActive" >
                <el-icon class="el-icon--left">
                  <Search />
                </el-icon>
                搜索
              </el-button>
            </div>
          </el-affix>
        </div>
        <template v-if="user && ((user.permissions == 1) || (user.permissions == 0))">
          <div>
            <el-row v-if="page == 0">
              <div :style="{'height': (height - 72) + 'px'}" v-infinite-scroll="() => {}" :infinite-scroll-disabled="true" style="overflow:auto;width: 100%">
                <el-col :span="20" :offset="2">
                  <el-card style="margin-top: 10px;" :body-class="form.type == -1 ? 'red-invoice-shadow h-100' : ''" :style="isSmall ? {'height': company.alias ? (form.has_contract !== false ? '918px' : '1008px') : ((form.type == -1) ? '956px' : (form.has_contract !== false ? '966px' : '1066px'))} : {'height': company.alias ? (form.has_contract !== false ? '682px' :'762px') : (form.has_contract !== false && form.type == 0 ? '650px' :'740px')}">
                    <h3 style="font-size: 15px; height: 25px;line-height: 25px;margin-bottom: 5px;letter-spacing: 1px">发票审批申请</h3>
                    <el-tag disable-transitions type="success" size="large" effect="plain" v-if="company.alias">{{company.alias}}分公司</el-tag>
                    <ApplyForm :form-data="form" @apply="onToSP"></ApplyForm>
                  </el-card>
                </el-col>
              </div>
            </el-row>
            <template v-if="page == 1">
              <div id="applyData">
                <el-table  :data="tableData"   table-layout="auto"  :style="{'height': (height - 150) + 'px'}" style="width: 100%;margin-top: 5px;"  border v-loading="loadingData"  element-loading-text="数据加载中, 请稍后" stripe>
                  <el-table-column type="expand">
                    <template #default="{row}">
                      <InvoiceInfoCard @onCopy="copyApplyInfo(row)" @onDownload="downloadPdf(row.no)" :row="row" :format="moneyFormat"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="开票名称" prop="invoice_name" show-overflow-tooltip >
                    <template #header>
                       <el-switch
                        v-model="searchCondition.type"
                        inline-prompt
                        size="large"
                        style="--el-switch-on-color: #ff4949; --el-switch-off-color: #13ce66"
                        active-text="开票名称 {类型：红字发票}"
                        inactive-text="开票名称 {类型：正字发票}"
                        :active-value="-1"
                        :inactive-value="0"
                        @change="delaySearch"
                    />
                    </template>
                    <template #default="scope">
                      <span :style="scope.row.type == -1 ? {'color': '#f56c6c'} : {}">{{scope.row.invoice_name}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column  label="状态" fixed="right" header-align="center">
                    <template #default="scope">
                      <ApplyStatusText :apply-status="applyStatus(scope.row.status, scope.row.reject)"/>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div style="margin-top: 10px;">
                <el-pagination style="float: right;height: 50px; line-height: 50px;margin-right: 2px" :pager-count="4" layout="total, prev, pager, next, slot" :total="pageInfo.total" :page-size="pageInfo.pageSize" v-model:current-page="pageInfo.current" @current-change="list">
                  <el-button circle @click="list" >
                    <el-icon :class="loadingData ? 'is-loading' : ''"><Refresh /></el-icon>
                  </el-button>
                  <el-button circle @click="searchActive">
                    <el-icon><Search /></el-icon>
                  </el-button>
                </el-pagination>
              </div>
            </template>
            <template v-if="page == 2">
              <el-row>
                <el-col :span="20" :offset="2">
                  <el-card style="margin-top: 10px;position: relative" :style="{'height': (height - 100) + 'px'}">
                    <el-row>
                      <el-col :span="20" :offset="2">
                        <el-form :model="userInfo" label-width="120px" label-position="top">
                          <el-form-item label="用户名">
                            <el-input v-model="userInfo.name" readonly/>
                          </el-form-item>
                          <el-form-item label="待审批申请数" v-if="false">
                            <el-input v-model="userInfo.counts" readonly/>
                          </el-form-item>
                          <el-form-item label="数据刷新时间" v-if="false">
                            <el-input v-model="userInfo.refresh_time" readonly/>
                          </el-form-item>
                          <el-form-item label="手机号">
                            <el-input v-model="userInfo.phone" readonly/>
                          </el-form-item>
                          <el-form-item label="登录时间">
                            <el-input v-model="userInfo.login_time" readonly/>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                    <el-button round style="position: absolute;bottom: 20px;width: 90%;left: 5%" @click="onLogout">退出登录</el-button>
                  </el-card>
                </el-col>
              </el-row>
            </template>
          </div>
          <div style="position: fixed; bottom: 0px; left: 0px; width: 100%;padding: 0;margin: 0" >
            <el-button-group  type="primary" style="width: 100%;">
              <el-button @dblclick.prevent :type="page == 0 ? 'success' : ''"   @click="appFunc(0)"  style="width: 33%;margin-left: 1px">
                <el-icon v-if="page == 0"><DArrowLeft/></el-icon><el-icon><Promotion/></el-icon><el-icon v-if="page == 0"><DArrowRight/></el-icon>
              </el-button>
              <el-button @dblclick.prevent :type="page == 1 ? 'success' : ''"    @click="appFunc(1);"  style="width: 34%">
                <el-icon v-if="page == 1"><DArrowLeft/></el-icon><el-icon><List /></el-icon><el-icon v-if="page == 1"><DArrowRight/></el-icon>
              </el-button>
              <el-button  @dblclick.prevent :type="page == 2 ? 'success' : ''"   @click="appFunc(2)"  style="width: 33%">
                <el-icon v-if="page == 2"><DArrowLeft/></el-icon><el-icon><User/></el-icon><el-icon v-if="page == 2"><DArrowRight/></el-icon>
              </el-button>
            </el-button-group>
          </div>
        </template>
        <template v-else>
          <el-row>
            <el-col :span="22" :offset="1">
              <div v-infinite-scroll="() => {}"   :style="{'height': (height - 40) + 'px'}" style="overflow: auto">
                <el-card style="margin-top: 10px;position: relative" >
                  <el-table v-if="isSmall" row-key="id" :data="tableData"  :style="{'height': (height - 150) + 'px'}" style="width: 100%" border v-loading="loadingData"  element-loading-text="数据加载中, 请稍后" stripe>
                    <el-table-column type="expand">
                      <template #default="props">
                        <div style="width: 100%; height: 100%;position: absolute;z-index: 9999999" ></div>
                        <el-card style="margin: 5px 10px;z-index: 1" :body-class="props.row.type == -1 ? 'red-invoice-shadow' : ''">
                          <ApplyForm :form-data="props.row" :read-only="true" >
                            <el-form-item label="申请时间">
                              <el-input v-model="props.row.create_time" readonly/>
                            </el-form-item>
                            <el-button v-if="false" type="primary" @click="copyApplyInfo(props.row)" style="width: 100%">复制本条所有信息</el-button>
                          </ApplyForm>
                        </el-card>
                      </template>
                    </el-table-column>
                    <el-table-column label="开票名称" prop="invoice_name" show-overflow-tooltip >

                      <template #default="scope">
                        <span :style="scope.row.type == -1 ? {'color': '#f56c6c'} : {}">{{scope.row.invoice_name}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="申请人" prop="applicant" show-overflow-tooltip  width="70"/>
                    <el-table-column  label="操作"  header-align="center" width="70">
                      <template #default="scope">
                        <ApplyStatusView :apply="scope.row">
                          <el-button
                              link
                              type="primary"
                              size="small"
                              @click.prevent="operation(scope.row)"
                          >
                            <ApplyStatusText :apply-status="applyStatus(scope.row.status, false)"/>
                          </el-button>
                        </ApplyStatusView>
                      </template>
                    </el-table-column>
                    <template #append>
                      <el-switch
                          v-model="searchCondition.type"
                          inline-prompt
                          size="large"
                          style="--el-switch-on-color: #ff4949; --el-switch-off-color: #13ce66; margin-top:15px"
                          active-text="发票类型： 红字发票"
                          inactive-text="发票类型：正字发票"
                          :active-value="-1"
                          :inactive-value="0"
                          @change="delaySearch"
                      />
                    </template>
                  </el-table>
                  <div  v-else>
                    <el-tabs v-model="searchCondition.type"  @tab-change="delaySearch">
                      <el-tab-pane :name="0">
                        <template #label>
                          <el-button type="primary" :plain="searchCondition.type != 0">正字发票</el-button>
                        </template>
                        <div id="main-table">
                          <el-table    :data="tableData"    table-layout="auto"  style="width: 100%" border v-loading="loadingData"  element-loading-text="数据加载中, 请稍后" stripe >
                            <el-table-column  prop="invoice_name" fixed label="开票名称" width="220" show-overflow-tooltip header-align="center"></el-table-column>
                            <el-table-column  fixed label="申请"  header-align="center" data-class="apply">
                              <el-table-column  prop="applicant"  label="" width="80" show-overflow-tooltip header-align="center">
                                <template #header>
                                  <el-icon style="font-size: 15px"><UserFilled /></el-icon>
                                </template>
                              </el-table-column>
                              <el-table-column prop="create_time" label="时间" width="160" header-align="center">

                              </el-table-column>
                            </el-table-column>
                            <el-table-column fixed label="项目" header-align="center"  >
                              <el-table-column prop="total" fixed label="合同总金额" width="120" show-overflow-tooltip header-align="center" align="center">
                                <template #default="scope">
                                  <span>{{moneyFormat(scope.row.total)}}</span>
                                </template>
                              </el-table-column>
                              <el-table-column prop="audit" fixed label="审计额" width="120" show-overflow-tooltip  header-align="center" align="center">
                                <template #default="scope">
                                  <span>{{moneyFormat(scope.row.audit)}}</span>
                                </template>
                              </el-table-column>
                              <el-table-column prop="apply" fixed label="本次开具金额" width="130" show-overflow-tooltip  header-align="center" align="center">
                                <template #default="scope">
                                  <span>{{moneyFormat(scope.row.apply)}}</span>
                                </template>
                              </el-table-column>
                              <el-table-column prop="applicant_comments" label="备注" width="160" show-overflow-tooltip header-align="center"/>
                              <el-table-column prop="has_contract" fixed label="合同" width="60" header-align="center">
                                <template #default="scope">
                                  <el-tag disable-transitions v-if="scope.row.has_contract" type="success">有</el-tag>
                                  <span v-else>&nbsp;</span>
                                </template>
                              </el-table-column>
                            </el-table-column>

                            <el-table-column label="状态"  fixed header-align="center" >
                              <el-table-column prop="status"  label="审批" width="80" header-align="center">
                                <template #default="scope">
                                  <ApplyStatusText :show-type="false" :apply-status="applyStatus(scope.row.status, scope.row.reject)"/>
                                </template>
                              </el-table-column>
                              <el-table-column label="发票" width="65" header-align="center">
                                <template #default="scope">
                                  <p v-if="scope.row.status === 3 && scope.row.no" style="text-align: center">
                                    <el-text @click="downloadPdf(scope.row.no)" style="cursor: pointer">
                                      <el-tooltip
                                          effect="dark"
                                          content="下载发票"
                                          placement="top"
                                      >
                                        <el-icon style="color: #409eff"><Download /></el-icon>
                                      </el-tooltip>
                                    </el-text>
                                  </p>
                                </template>
                              </el-table-column>
                              <el-table-column prop="reject"  label="驳回" width="60">
                                <template #default="scope">
                                  <el-tag disable-transitions type="warning" v-if="scope.row.reject">是</el-tag>
                                  <span v-else>&nbsp;</span>
                                </template>
                              </el-table-column>
                            </el-table-column>
                            <el-table-column  label="审核"  header-align="center">
                              <el-table-column prop="review_time" label="时间" width="180" header-align="center"/>
                              <el-table-column prop="review_comments" label="备注" width="200" :show-overflow-tooltip="{placement: 'left'}" header-align="center"/>
                            </el-table-column>
                            <el-table-column prop="invoice_time" label="开票时间" width="180" header-align="center"/>
                            <el-table-column  label="操作" fixed="right" header-align="center">
                              <template #default="scope">
                                <ApplyStatusView :apply="scope.row">
                                  <el-button
                                      link
                                      type="primary"
                                      size="small"
                                      @click.prevent="operation(scope.row)"
                                  >
                                    <ApplyStatusText :apply-status="applyStatus(scope.row.status, false)"/>
                                  </el-button>
                                </ApplyStatusView>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane  :name="-1">
                        <template #label>
                          <el-button  type="danger" :plain="searchCondition.type != -1">红字发票</el-button>
                        </template>
                        <div id="red-table">
                          <el-table   :data="tableData"    table-layout="auto"  style="width: 100%" border v-loading="loadingData"  element-loading-text="数据加载中, 请稍后"  stripe >
                            <el-table-column  prop="invoice_name" fixed label="开票名称" width="220" show-overflow-tooltip header-align="center"></el-table-column>
                            <el-table-column  fixed label="申请"  header-align="center" data-class="apply">
                              <el-table-column  prop="applicant"  label="" width="80" show-overflow-tooltip header-align="center">
                                <template #header>
                                  <el-icon style="font-size: 15px"><UserFilled /></el-icon>
                                </template>
                              </el-table-column>
                              <el-table-column prop="create_time" label="时间" width="160" header-align="center"/>
                            </el-table-column>
                            <el-table-column fixed label="原正字发票" header-align="center"  >
                              <el-table-column prop="invoice_no" fixed label="发票号" width="200" show-overflow-tooltip  header-align="center" align="center"/>
                              <el-table-column prop="invoice_build_time" fixed label="开票日期" width="100" header-align="center" />
                              <el-table-column prop="total" fixed label="开票金额" width="120" show-overflow-tooltip header-align="center" align="center">
                                <template #default="scope">
                                  <span>{{moneyFormat(scope.row.total)}}</span>
                                </template>
                              </el-table-column>
                            </el-table-column>
                            <el-table-column fixed label="红字发票" header-align="center"  >
                              <el-table-column prop="apply" fixed label="本次开具金额" width="130" show-overflow-tooltip  header-align="center" align="center">
                                <template #default="scope">
                                  <span style="font-size: 20px;color: #f56c6c">-</span>&nbsp;<span>{{moneyFormat(scope.row.apply)}}</span>
                                </template>
                              </el-table-column>
                              <el-table-column prop="applicant_comments" label="备注" width="160" show-overflow-tooltip header-align="center"/>
                            </el-table-column>
                            <el-table-column label="状态"  fixed header-align="center" >
                              <el-table-column prop="status"  label="审批" width="80" header-align="center">
                                <template #default="scope">
                                  <ApplyStatusText :show-type="false" :apply-status="applyStatus(scope.row.status, scope.row.reject)"/>
                                </template>
                              </el-table-column>
                              <el-table-column label="发票" width="65" header-align="center">
                                <template #default="scope">
                                  <p v-if="scope.row.status === 3 && scope.row.no" style="text-align: center">
                                    <el-text @click="downloadPdf(scope.row.no)" style="cursor: pointer">
                                      <el-tooltip
                                          effect="dark"
                                          content="下载发票"
                                          placement="top"
                                      >
                                        <el-icon style="color: #409eff"><Download /></el-icon>
                                      </el-tooltip>
                                    </el-text>
                                  </p>
                                </template>
                              </el-table-column>
                              <el-table-column prop="reject"  label="驳回" width="60">
                                <template #default="scope">
                                  <el-tag disable-transitions type="warning" v-if="scope.row.reject">是</el-tag>
                                  <span v-else>&nbsp;</span>
                                </template>
                              </el-table-column>
                            </el-table-column>
                            <el-table-column  label="审核"  header-align="center">
                              <el-table-column prop="review_time" label="时间" width="180" header-align="center"/>
                              <el-table-column prop="review_comments" label="备注" width="200" :show-overflow-tooltip="{placement: 'left'}" header-align="center"/>
                            </el-table-column>
                            <el-table-column prop="invoice_time" label="开票时间" width="180" header-align="center"/>
                            <el-table-column  label="操作" fixed="right" header-align="center">
                              <template #default="scope">
                                <ApplyStatusView :apply="scope.row">
                                  <el-button
                                      link
                                      type="primary"
                                      size="small"
                                      @click.prevent="operation(scope.row)"
                                  >
                                    <ApplyStatusText :apply-status="applyStatus(scope.row.status, false)"/>
                                  </el-button>
                                </ApplyStatusView>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>

                      </el-tab-pane>
                      <el-tab-pane  :name="1" v-if="user && (user.permissions & 0b1000) != 0">
                        <template #label>
                          <el-button  type="info" :plain="searchCondition.type != 1">用户管理</el-button>
                        </template>
                        <div >
                          <el-table   :data="userTableData"    table-layout="auto"  style="width: 100%" border v-loading="loadingData"  element-loading-text="数据加载中, 请稍后"  stripe >
                            <el-table-column  prop="username" fixed label="用户名" width="220" show-overflow-tooltip header-align="center" align="center"></el-table-column>
                            <el-table-column prop="phone" label="手机号" width="220" header-align="center" align="center"/>
                            <el-table-column prop="role" label="角色" width="120" header-align="center" align="center" v-if="false">
                              <template #default="scope">
                                <el-tag v-if="scope.row.role == 0">员工</el-tag>
                                <el-tag v-else-if="scope.row.role == 1" type="success">审核人</el-tag>
                                <el-tag v-else-if="scope.row.role == 2" type="warning">开票人</el-tag>
                                <el-tag v-else>未定义</el-tag>
                              </template>
                            </el-table-column>
                            <el-table-column prop="permissions" label="权限" width="270" header-align="center" align="center">
                              <template #default="scope">
                                <el-space :size="10" spacer="|">
                                  <el-tag v-if="(scope.row.permissions & 0b1000) != 0" type="success">管理</el-tag>
                                  <el-tag v-if="(scope.row.permissions & 0b100) != 0" type="warning">开票</el-tag>
                                  <el-tag v-if="(scope.row.permissions & 0b10) != 0" type="primary">审批</el-tag>
                                  <el-tag v-if="(scope.row.permissions & 0b1) != 0" type="info">申请</el-tag>
                                  <el-tag v-if="!scope.row.permissions" >未配置</el-tag>
                                </el-space>
                              </template>
                            </el-table-column>
                            <el-table-column prop="create_time" label="创建时间" width="180" header-align="center" align="center"/>
                            <el-table-column  label="操作" fixed="right" header-align="center" align="center">
                              <template #default="scope">
                                <el-button :data-id="scope.row.id" type="success" plain size="small" @click="beforeUpdateUser(scope.row)">编辑</el-button>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                  <div style="margin-top: 10px;" >
                    <el-pagination style="float: right;height: 50px; line-height: 50px;margin-right: 2px" layout="total, prev, pager, next, slot" :total="pageInfo.total" :pager-count="3" :page-size="pageInfo.pageSize" v-model:current-page="pageInfo.current" @current-change="searchCondition.type != 1 ? list() : listUser()">
                      <el-button circle @click="searchCondition.type != 1 ? list() : listUser()" >
                        <el-icon :class="loadingData ? 'is-loading' : ''"><Refresh /></el-icon>
                      </el-button>
                      <el-button circle @click="searchActive" v-if="searchCondition.type != 1">
                        <el-icon><Search /></el-icon>
                      </el-button>

                      <el-button circle @click="dialogs.add_user = true" v-if="searchCondition.type == 1">
                        <el-icon><Plus /></el-icon>
                      </el-button>

                      <el-tag  style="margin-left: 10px"   type="success" v-if="false" >
                        <el-icon><CircleCheck /></el-icon>
                        <template v-if="user.permissions && ((user.permissions & 0b10) != 0)">
                          审核
                        </template>
                        <template v-if="user.permissions && ((user.permissions & 0b110) == 0b110)">
                          |
                        </template>
                        <template v-if="user.permissions && ((user.permissions & 0b100) != 0)">
                          开票
                        </template>
                        <template v-if="user.permissions && ((user.permissions & 0b1110) == 0b1110)">
                          |
                        </template>
                        <template v-if="user.permissions && ((user.permissions & 0b1000) != 0)">
                          管理
                        </template>
                      </el-tag>
                    </el-pagination>
                  </div>
                </el-card>
              </div>

            </el-col>
          </el-row>



          <el-dialog v-model="dialogs.review"  width="80%" top="0vh" :lock-scroll="true" @closed="onScrollTop">
            <template #title>
              <el-text type="success" >【{{currentRow.applicant}}】</el-text><span style="letter-spacing: 1px">的开票信息审核</span>
              <p style="height: 20px;line-height: 20px"><el-icon><InfoFilled /></el-icon><span style="font-size: 13px">「点击阴影区域，可关闭操作窗口」</span></p>
            </template>
            <el-row :gutter="30">
              <el-col :span="12" v-if="!isSmall">
                <el-card style="height: 635px" :body-class="currentRow.type == -1 ? 'red-invoice-shadow': ''">
                  <ApplyForm :form-data="currentRow" :read-only="true"></ApplyForm>
                </el-card>
              </el-col>
              <el-col :span="isSmall ? 24 : 12">
                <el-card :style="isSmall ? {'height': '500px'} :{'height': '635px'}">
                  <el-form :model="form" label-width="120px" label-position="top" size="default" style="margin-top: 20px">
                    <el-divider content-position="center"><el-text type="primary">审核操作</el-text></el-divider>
                    <el-form-item label="开票信息申请时间">
                      <el-input v-model="currentRow.create_time" readonly  />
                    </el-form-item>
                    <el-form-item label="审核意见【可选】">
                      <el-input v-model="currentRow.review_comments" :readonly="currentRow.status >= 2" maxlength="100" show-word-limit type="textarea" placeholder="请输入备注信息" />
                    </el-form-item>
                    <el-row style="margin-top: 40px" v-if="currentRow.reject">
                      <el-col :span="22" :offset="1">
                        <el-button type="danger">已被驳回</el-button>
                      </el-col>
                    </el-row>
                    <el-row style="margin-top: 40px" v-else-if="currentRow.status == 1">
                      <el-col :span="10" :offset="1">
                        <el-button type="danger" @click="examineOp(currentRow, 1)">驳回</el-button>
                      </el-col>
                      <el-col :span="10" :offset="1">
                        <el-button type="success" @click="examineOp(currentRow, 2)">通过</el-button>
                      </el-col>
                    </el-row>
                    <el-form-item label="审核通过时间" v-else-if="currentRow.status >= 2">
                      <el-input v-model="currentRow.review_time"   readonly/>
                    </el-form-item>
                    <template v-if="currentRow.status >= 2 && (user.permissions && ((user.permissions & 0b100) != 0))">
                      <el-divider content-position="center" style="margin-top: 50px">
                        <el-text type="danger">确认开票</el-text>
                      </el-divider>
                      <el-row style="margin-top: 50px" >
                        <el-col :span="16" :offset="4">
                          <el-button  type="primary" style="width: 100%" @click="beforeUploadFile(currentRow)" v-if="currentRow.status == 2">上传发票并开票</el-button>
                          <el-tag v-else-if="currentRow.status == 3">已开票</el-tag>
                        </el-col>
                      </el-row>
                    </template>



                  </el-form>
                </el-card>
              </el-col>
              <el-col v-if="user.permissions && ((user.permissions & 0b1000) != 0)">
                <el-row  >
                  <el-col :span="16" :offset="4">
                    <el-button  type="warning" style="width: 100%; margin-top: 20px" @click="beforeDeleteInvoice(currentRow)" >删除发票</el-button>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

          </el-dialog>
          <el-dialog v-model="dialogs.add_user"  width="80%" @close="emptyNewUserInfo">
            <template #title>
              <span style="letter-spacing: 1px">{{newUser.id ? '编辑用户' : '添加用户'}}</span>
              <p style="height: 20px;line-height: 20px"><el-icon><InfoFilled /></el-icon><span style="font-size: 13px">「点击阴影区域，可关闭操作窗口」</span></p>
            </template>
            <el-card>
              <el-form label-width="120px" label-position="top">
                <el-form-item label="用户名" >
                  <el-input v-model="newUser.username"   />
                </el-form-item>
                <el-form-item label="密码" >
                  <el-input v-model="newUser.password"   />
                </el-form-item>
                <el-form-item label="手机号" >
                  <el-input v-model="newUser.phone"   />
                </el-form-item>
                <el-form-item label="权限" >
                  <el-checkbox-group v-model="newUser.permissions">
                    <el-checkbox :label="0b1" >
                      <el-tag type="info">申请</el-tag>
                    </el-checkbox>
                    <el-checkbox :label="0b10" >
                      <el-tag type="primary">审批</el-tag>
                    </el-checkbox>
                    <el-checkbox :label="0b100"  >
                      <el-tag type="warning">开票</el-tag>
                    </el-checkbox>
                    <el-checkbox :label="0b1000" >
                      <el-tag type="success">管理</el-tag>
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="账号状态"  v-if="newUser.id">
                  <el-switch
                      v-model="newUser.status"
                      active-text="正常"
                      inactive-text="注销"
                      :active-value="0"
                      :inactive-value="-1"
                  />
                </el-form-item>
                <el-button style="width: 100%" @click="onAddUser">{{newUser.id ? '更新' : '添加'}}</el-button>
              </el-form>
            </el-card>
          </el-dialog>
          <el-dialog v-model="dialogs.apply_invoice"  fullscreen>
            <template #title>
              <span style="letter-spacing: 1px">开票审批申请</span>
              <h3 v-if="company.alias" style="margin-top: 5px">
                <el-tag disable-transitions type="success" size="large" effect="plain" >{{company.alias}}分公司</el-tag>
              </h3>
            </template>
            <el-row style="margin-top: -10px">
              <el-col :span="22" :offset="1" >
                <el-card :style="isSmall ? {'height': (form.type == -1 ? '916px' : form.has_contract === false ? '1036px' : '936px')} : {'height': ((form.type === 0 && form.has_contract !== false) ? '640px' : '720px')}" v-if="dialogs.apply_invoice" :body-class="form.type == -1 ? 'red-invoice-shadow h-100' : ''">
                  <ApplyForm :form-data="form" @apply="onToSP"></ApplyForm>
                </el-card>
              </el-col>
            </el-row>
          </el-dialog>
          <el-dialog v-model="dialogs.upload_file"  width="80%">
            <template #title>
              <span style="letter-spacing: 1px">上传发票并开票</span>
              <p style="height: 20px;line-height: 20px"><el-icon><InfoFilled/></el-icon><span style="font-size: 13px">「点击阴影区域，可关闭操作窗口」</span></p>
            </template>
            <UploadCard :url='uploadFile.url' :access_token="access_token" :success="uploadFileSuccess" :remove="removeFile" v-if="dialogs.upload_file">
              <el-button style="width: 100%" @click="examineOp(uploadFile.currentRow, 3)">开票完成</el-button>
            </UploadCard>
          </el-dialog>
        </template>
      </div>

      <el-dialog v-model="isSearch"  :width="isSmall ? '80%' : '400px'" top="15vh">
        <template #title>
          <el-icon class="is-loading" v-if="loadingData" style="margin-right: 7px;margin-top: -3px">
            <Loading style="color: #409eff"/>
          </el-icon>
          <el-icon  v-else style="margin-right: 7px;margin-top: -3px">
            <Search/>
          </el-icon>
          <span style="letter-spacing: 1px">搜索条件设定</span>
          <p style="height: 20px;line-height: 20px"><el-icon><InfoFilled/></el-icon><span style="font-size: 13px">「点击阴影区域，可关闭操作窗口」</span></p>
        </template>
        <SearchCard :form-data="searchCondition" @search="delaySearch" :is-small="isSmall"/>
      </el-dialog>

      <el-dialog v-model="dialogs.export"  :width="isSmall ? '80%' : '400px'" top="30vh" :close-on-click-modal="false">
        <template #title>
          <span style="letter-spacing: 1px">数据导出</span>
        </template>
        <el-steps  :active="exportData.active" finish-status="success" style="text-align: left" align-center>
          <el-step title="搜索条件设定" >
            <template #description>
              <el-row style="margin-top: 10px">
                <el-col>
                  <el-button type="primary" @click="searchActive();exportData.active = 1" round size="small" plain v-if="exportData.active == 0">设定</el-button>
                </el-col>
              </el-row>
            </template>
          </el-step>
          <el-step title="数据导出" >
            <template #description>
              <el-row style="margin-top: 10px">
                <el-col>
                  <el-button type="primary" @click="onExportData" round size="small" plain v-if="exportData.active == 1">导出</el-button>
                </el-col>
              </el-row>
            </template>
          </el-step>
        </el-steps>
      </el-dialog>

      <el-dialog v-model="dialogs.redInvoiceTip" :close-delay="300" :width="isSmall ? '90%' : '30%'" top="15vh" center :close-on-click-modal="false" :show-close="false" class="red-invoice-shadow">
        <template #header>
          <p style="letter-spacing: 1px">开具红字发票注意事项</p>
        </template>
        <div style="line-height: 35px;letter-spacing: 1px">
          <el-card >
            <p style="line-height: 30px;">
              注意：如本月需开具同等金额的红字发票（相对应的正数发票开具时间在本月），无需审批，申请人直接去财务处理。
            </p>
          </el-card>
          <p style="color: #f56c6c;margin-top: 10px">
            以下情况开具红字发票需走手机端审批流程：
          </p>
          <p >
            1、本月不再开具同等金额的红字发票（相对应的正数发票开具时间不是本月）。
          </p>
          <p >
            2、本月开具不同金额的红字发票。
          </p>
        </div>
        <template #footer>
          <span >
            <el-button type="primary" plain @click="dialogs.redInvoiceTip = false" style="width: 90%" round>
              确认
            </el-button>
          </span>
        </template>
      </el-dialog>

      <el-dialog v-model="dialogs.invalidInvoice" :close-delay="300" :width="isSmall ? '90%' : '30%'" top="40vh" center :close-on-click-modal="false" >
        <template #header>
          <p style="letter-spacing: 1px">确认删除发票</p>
        </template>
        <template #footer>
          <span >
            <el-button type="danger" plain @click="onInvalidInvoice" style="width: 90%" round>
              确认
            </el-button>
          </span>
        </template>
      </el-dialog>


      <el-dialog v-model="dialogs.alert.show" :close-delay="300" :width="isSmall ? '90%' : '30%'" top="35vh" center :close-on-click-modal="false" :show-close="false" >
        <template #header>
          <div style="color: #FF0000;letter-spacing: 2px" v-if="dialogs.alert.code != 0">
            <el-icon><WarningFilled /></el-icon>&nbsp;提交失败
          </div>
          <div style="color: #409eff;letter-spacing: 2px" v-else>
            <el-icon><SuccessFilled /></el-icon>&nbsp;提交成功
          </div>
        </template>
        <div style="line-height: 35px;letter-spacing: 2px;text-align: center;font-size: 18px">
          <el-card >
            <p style="color: #FF0000" v-if="dialogs.alert.code != 0">
              {{dialogs.alert.message}}
            </p>
            <p style="color: #409eff;" v-else>
              {{dialogs.alert.message}}
            </p>
          </el-card>
        </div>
        <template #footer>
          <span >
            <el-button type="primary" plain @click="dialogs.alert.show = false" style="width: 90%" round>
              确认
            </el-button>
          </span>
        </template>
      </el-dialog>
    </el-watermark>
  </div>
  <iframe :src="downloadPdfUrl" style="visibility: hidden"></iframe>
</template>
<script setup>
import {
  DArrowRight,
  DArrowLeft,
  User,
  Promotion,
  List,
  InfoFilled,
  UserFilled,
  Refresh,
  CircleCheck,
  ArrowDown,
  Download,
  Search,
  Loading, Plus,WarningFilled, SuccessFilled
} from '@element-plus/icons-vue';
import {ElMessageBox, ElMessage, ElNotification} from 'element-plus';
import {useSize} from './assets/js/size';
import {computed, onMounted, reactive, ref, watch,} from "vue";
import axios from 'axios';
import ApplyForm from "@/components/ApplyForm";
import LoginForm from "@/components/LoginForm";
import _ from "lodash";
import InvoiceInfoCard from "@/components/InvoiceInfoCard";
import ApplyStatusText from "@/components/utils/ApplyStatusText";
import ApplyStatusView from "@/components/ApplyStatusView";
import UploadCard from "@/components/utils/UploadCard";
import SearchCard from "@/components/SearchCard";
let {height, width} = useSize();
const loginSuccess = ref(false);
const access_token = ref(null);
const downloadPdfUrl = ref(null);
const isSearch = ref(false);
const onScrollTop = () => scrollTo(0, 0);
const moneyFormat = (v) => {
  if (v == null || v == '') return '';
  let money = [...(v.replace("-", ''))];
  let main = money.slice(0, money.length - 3);
  let sub = money.slice(money.length - 3, money.length);
  let counter = 0;
  let result = main.reduceRight((format, current) => {
      return current + ( (counter++ % 3 == 0) ? "," : '') + format;
  }, '')
  return  result.substring(0, result.length - 1) + sub.join('');
}

const searchCondition = reactive({
  keyword: null,
  has_contract: null,
  reject: null,
  status: null,
  begin: null,
  end: null,
  range:null,
  type: 0,
  start_proc: -1,
  invoice_type: -1,
})

function configObjectNull(obj, excepts){
  if (obj){
    for (let key in obj){
      if (Array.isArray(excepts) && excepts.length != 0){
        if (!excepts.find(item => item === key)){
          obj[key] = null;
        }
      }
      else {
        obj[key] = null;
      }
    }
  }
}



const isSmall = computed(() => width.value < 630);
const dialogs = reactive({
  review: false,
  add_user: false,
  apply_invoice: false,
  upload_file: false,
  export: false,
  redInvoiceTip: false,
  invalidInvoice: false,
  alert: {
    message: '',
    code:0,
    show: false,
  },
})

const uploadFile = reactive({
  path: null,
  currentRow: null,
  url: null,
})

let currentDeleteInvoiceId = null;

let searchMessage = null;
const searchActive = () => {
  isSearch.value = true;
  if (!searchMessage){
    searchMessage = ElMessage({
      showClose: true,
      message: '搜索模式已打开，关闭提示，可关闭搜索模式',
      type: 'success',
      grouping: true,
      offset: 0,
      duration: 0,
      onClose: () => {
        isSearch.value = false;
        if (searchMessage != null){
          searchMessage.close();
          searchMessage = null;
        }
        configObjectNull(searchCondition, ['type','start_proc', 'invoice_type'])
        delaySearch();
      }
    })
  }
}

const exportData = reactive({
  active: 0,
})

const onExportData = () => {
  exportData.active = 0;
  dialogs.export = false;
  const param = searchParam();
  let options = {
    title: '',
    message: '没有符合要求的数据',
    type: 'error',
  };
  if (tableData.value.length != 0){
    options = {
      title: '',
      message: '报表即将生成',
      type: 'success',
      onClose: () => {
        downloadPdfUrl.value = BASE_URL  + '/invoice/export/excel?v=' + new Date().getTime() +  param + '&access_token=' + access_token.value + company.areaParam();
      }
    }
  }
  ElNotification(options)
  if (searchMessage != null){
    searchMessage.close();
  }

}
const pageInfo = reactive({
  total:0,
  current: 1,
  pageSize: 15,
})

const beforeUploadFile = (row) => {
  uploadFile.path = null;
  uploadFile.currentRow = row;
  uploadFile.url = BASE_URL + "/invoice/upload/pdf";
  dialogs.upload_file = true
}


const beforeDeleteInvoice = (row) => {
  currentDeleteInvoiceId = row.id;
  dialogs.invalidInvoice = true
}

const onInvalidInvoice = () => {

  axios.get(BASE_URL + `/invoice/invalid/${currentDeleteInvoiceId}`)
      .then(function (response) {
        ElNotification({
          title: '',
          message: response.data.msg,
          type: response.data.code == 0 ? 'success': 'error'
        })
      })
      .catch(function () {
        tableData.value = []
        ElNotification({
          title: '',
          message: '网络请求失败，请稍后重试',
          type: 'error'
        })
      })
      .finally(function () {
        setTimeout(() => {
          dialogs.invalidInvoice = false;
          currentDeleteInvoiceId = null;
          dialogs.review = false;
          list();
        }, 600)
      });


}



const uploadFileSuccess = function (resp){
  if (resp.code == 0){
    uploadFile.path = resp.data;
    ElNotification({
      title: '',
      message: '文件已上传',
      type: 'success',
    })
  }
}
const removeFile = function (){
  uploadFile.path = null;
  ElNotification({
    title: '',
    message: '移除文件成功',
    type: 'success',
  })
}



const downloadPdfFile = _.debounce((no) => {
  ElNotification({
    title: '',
    message: '文件即将下载',
    type: 'success',
    onClose: () => {
      downloadPdfUrl.value = BASE_URL  + '/invoice/load/' + no + '/' + access_token.value + "?v=" + new Date().getTime();
    }
  })
},1000, {maxWait: 3000})

const downloadPdf = (no) => {
  downloadPdfFile(no);
}

const newUser = reactive({
  id: '',
  username: '',
  password: '',
  phone: '',
  permissions: [0b1],
  status: 0,
})

const toLoginInfo =  _.debounce(() => {
  onLogout();
  ElNotification({
    title: '',
    message: '请登录',
    type: 'error',
  })
},500, {maxWait: 1000})

//区域：0：青岛升平电气有限公司，1：青岛升平电气有限公司沧口分公司，2：青岛升平电气有限公司黄岛分公司

function Company(type, area){
  this.type = type;
  this.area = area;
  this.name = area[type];
  this.alias = this.name === '青岛升平电气有限公司' ? '' : this.name.slice(0, 2)
}

Company.prototype.areaParam = function (method = 'get'){
  if (method === 'get') return "&area=" + this.type;
  else return this.type;
}

const company = new Company('0',  ["青岛升平电气有限公司", "沧口分公司", "黄岛分公司"]);



axios.interceptors.request.use(function (config) {
  config.headers['apiv'] = '1.0';
  config.headers['pv'] = '1.0';
  if (!loginSuccess.value && !config.url.includes('login')){
    return config;
  }
  let user = JSON.parse(localStorage.getItem("user"));
  config.headers['access_token'] = user?.access_token;
  access_token.value = user?.access_token;

  if (config.method === 'post'){
    if (config.url.includes('apply')){
      config.data['area'] = company.areaParam('post');
    }
  }
  else if(config.method === 'get'){
    if (config.url.includes('history')){
      config.url = config.url +  company.areaParam()
    }
  }

  config.timeout = 3500;
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  if(response.data.code == -2){
    toLoginInfo();
  }
  return response;
},function (error) {
  return Promise.reject(error);
});


const tableData = ref([]);
const userTableData = ref([]);

//审核操作
const examineOp = function (row, type){
  let status = 1;
  if (type == 1){
    //reject
    row.reject = true;
    status = 2;
  }
  else if(type == 2){
    //agree
    status = 2;
  }
  else if(type == 3){
    //invoice
    status = 3;
    row.path = uploadFile.path;
  }
  axios.post(BASE_URL + '/invoice/examine', row)
      .then(function (response) {
        let success = response.data.code == 0;
        if (success){
          row.status = status;
          if (type == 3){
            setTimeout(() => dialogs.upload_file = false, 1000);
          }
        }
        ElMessage({message: response.data.msg, type: success ? 'success' : 'info'})
      })
      .catch(function () {
        ElMessage({message: '数据请求异常，请稍后重试', type: 'error'})
      }).finally(() => {
    list(() => {
      tableData.value.forEach(item => {
        if (item.id == row.id){
          for (let key in item){
            currentRow[key] = item[key];
          }
        }
      })
    })
  });
}

// const BASE_URL = 'http://192.168.5.104:8080/invoicing_war_exploded';
// const BASE_URL = 'http://192.168.200.188:8081/oa';
const BASE_URL = window.location.host == "192.168.200.67" ? 'http://192.168.200.67:8080/oa' : 'https://fp.spepi.cn/oa';
const loadingData = ref(false);

function searchParam(){
  const keyword = searchCondition.keyword === null ? '' : '&keyword=' + searchCondition.keyword;
  if(searchCondition.status == null){
    searchCondition.reject = null;
  }
  else if (searchCondition.status == '-1'){
    searchCondition.reject = true;
  }
  else {
    searchCondition.reject = false;
  }
  const type = '&type=' + ((searchCondition.type != null) ?  searchCondition.type : '');
  const status = '&status=' + (searchCondition.status != null ?  ((searchCondition.status == '-1') ? '2' : searchCondition.status)   : "");
  const has_contract = searchCondition.has_contract === null ? '' : '&has_contract=' + (searchCondition.has_contract ? 1 : 0);
  const reject = searchCondition.reject == null ? '' : '&reject=' + (searchCondition.reject ? 1 : 0);
  const begin = searchCondition.begin == null ? '' : ('&begin=' +  searchCondition.begin);
  const end = searchCondition.end == null ? '' : ('&end=' +  searchCondition.end);
  const range = searchCondition.range == null ? '' : '&range=' + searchCondition.range;
  const start_proc = searchCondition.start_proc == -1 ? '' : '&start_proc=' + searchCondition.start_proc;
  const invoice_type = searchCondition.invoice_type == -1 ? '' : '&invoice_type=' + searchCondition.invoice_type;
  return status + has_contract + reject + keyword + begin + end + range + start_proc + invoice_type + type;
}

function list(callback) {
  if (!loginSuccess.value) return;
  pageInfo.pageSize = isSmall.value ? 10 : 15;
  loadingData.value = true;


  axios.get(BASE_URL + `/invoice/history?pageSize=${pageInfo.pageSize}&pageNum=${pageInfo.current}` + searchParam())
      .then(function (response) {
        if (response.data.code == 0){
          let result = response.data.data;
          pageInfo.total = result.total;
          setTimeout(() => {
            tableData.value = result.list
            if (callback && (typeof callback == 'function')) callback();
          }, 500)
        }
      })
      .catch(function () {
        tableData.value = []
        ElNotification({
          title: '',
          message: '网络请求失败，请稍后重试',
          type: 'error'
        })
      })
      .finally(function () {
        setTimeout(() => loadingData.value = false, 600)
      });
}


function listUser(callback) {
  if (!loginSuccess.value) return;
  pageInfo.pageSize = isSmall.value ? 10 : 15;
  loadingData.value = true;

  axios.get(BASE_URL + `/invoice/users?pageSize=${pageInfo.pageSize}&pageNum=${pageInfo.current}`)
      .then(function (response) {
        if (response.data.code == 0){
          let result = response.data.data;
          pageInfo.total = result.total;
          setTimeout(() => {
            userTableData.value = result.list
            if (callback && (typeof callback == 'function')) callback();
          }, 500)
        }
      })
      .catch(function () {
        userTableData.value = []
        ElNotification({
          title: '',
          message: '网络请求失败，请稍后重试',
          type: 'error'
        })
      })
      .finally(function () {
        setTimeout(() => loadingData.value = false, 600)
      });
}

const delaySearch = _.debounce(() => {
  if (searchCondition.type == 1) listUser();
  else list();
},1000, {maxWait: 3000})

onMounted(() => {
  list();
})

watch(isSmall, function () {
  list();
  searchCondition.range = null;
})

watch(() => searchCondition.type, newType => {
  if (newType == 1) return;
  if (newType == -1){
    searchCondition.has_contract = null;
  }
  loadingData.value = true;
})


const applyStatus = (status, reject) => {
  switch (status){
    case 1: return {
      info: '待审核',
      type: 'primary'
    };
    case 2: {
      if (reject){
        return  {
          info: "被驳回",
          type: 'danger'
        }
      }
      else {
        return {
          info: '待开票',
          type: 'success'
        }
      }
    }
    case 3: return  {
      info: '已开票',
      type: 'success'
    };
  }
  return {
    info: '错误',
    type: 'error'
  };
}

const currentRow = reactive({})
const operation = (data) => {
  for (let key in data){
    currentRow[key] = data[key];
  }
  if (data.status >= 1 && data.status <= 3){
    dialogs.review = true;
  }
}


onMounted(() => {
  document.getElementById('container').addEventListener('touchstart', function(event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  }, false);

  // 启用双击缩放
  document.getElementById('container').addEventListener('touchend', function(event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  }, false);
})

const font = {fontSize: 16,color: 'rgba(0,0,0,0.09)'};
const loading = ref(false);

const userInfo = reactive({
  name: '',
  counts: '',
  login_time: '',
  refresh_time: '',
  phone: ''
})
const page = ref(0);
let user = ref(JSON.parse(localStorage.getItem('user')))
if (user.value && user.value.access_token){
  loginSuccess.value = true;
  userInfo.login_time = user.value.current_login_time;
  userInfo.name = user.value.alias;
  userInfo.phone = user.value.phone;
  access_token.value = user.value.access_token;
}

const loginInfo = reactive({
  username:'',
  password: ''
})


const onLogin = function (){
  loading.value = true;
  let postData = {
    username: loginInfo.username,
    password: loginInfo.password
  }
  axios.post(BASE_URL + '/invoice/login', postData)
      .then(function (response) {
        if (response.data.code == 0){
          loginInfo.password = '';
          loginSuccess.value = true;
          page.value = 0
          user.value = response.data.data;
          localStorage.setItem('user', JSON.stringify(user.value))
          userInfo.name = user.value.alias;
          userInfo.login_time = user.value.current_login_time;
          userInfo.phone = user.value.phone;
          access_token.value = user.value.access_token;
          form.type = 0;
          pageInfo.current = 1;
          searchCondition.type = 0;
          list()
        }
        ElMessage({message: response.data.msg, type: response.data.code == 0 ? 'success' : 'info'})
      })
      .catch(function () {
        ElMessage({message: '数据请求异常，请稍后重试', type: 'error'})
      }).finally(() => {
        loading.value = false;

  });
}
const onLogout = function (){
  localStorage.removeItem('user');
  user.value = null;
  loginSuccess.value = false;
  dialogs.review = false;
  access_token.value = null;
  if (searchMessage != null){
    searchMessage.close();
  }
}

const beforeUpdateUser = (user) => {
  newUser.id = user.id;
  newUser.phone = user.phone;
  newUser.username = user.username;
  newUser.permissions = [];
  let permissions = user.permissions;
  const pushPermissions = (permissionBit) => {
    if ((permissions & permissionBit) != 0){
      newUser.permissions.push(permissionBit);
    }
  };
  [0b1000, 0b100, 0b10, 0b1].forEach(pushPermissions);
  dialogs.add_user = true;

}

const emptyNewUserInfo = () => {
  newUser.id = '';
  newUser.phone = '';
  newUser.password = ''
  newUser.username = '';
  newUser.permissions = [0b1];
  newUser.status = 0;
}

const onAddUser = function (){
  let postNewUser = {...newUser};
  postNewUser.permissions = postNewUser.permissions.reduce((sum, current) => sum + current, 0)
  let path = postNewUser.id ? '/invoice/updateUser' : '/invoice/addUser'
  axios.post(BASE_URL + path, postNewUser)
      .then(function (response) {
        ElMessage({message: response.data.msg, type: 'success'})
      })
      .catch(function () {
        ElMessage({message: '数据请求异常，请稍后重试', type: 'error'})
      }).finally(() => {
        listUser();
        setTimeout(() => dialogs.add_user = false , 300)
  });
}


const appFunc = (type) => {
  page.value = type;
  if (page.value == 1){
    list()
  }
}

const onToSP = () => {
  ElMessageBox.confirm(
      '确认提交开票申请信息？',
      '',
      {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'info',
        showCancelButton: false,
        closeOnClickModal: false
      }
  )
      .then(() => {
        let postData = {...form};
        // postData.area = 1;
        axios.post(BASE_URL + '/invoice/apply', postData)
            .then(function (response) {
              if (response.data.code == 0){
                // for (let key in form){
                //   form[key] = '';
                // }
                configObjectNull(form, ['type'])
              }
              // ElMessage({message: response.data.msg, type: response.data.code == 0 ? 'success' : 'error', duration: 10000})
              dialogs.alert.show = true;
              dialogs.alert.message = response.data.msg.replace("项目", '合同');
              dialogs.alert.code =  response.data.code;
            })
            .catch(function () {
              // ElMessage({message: '数据请求异常，请稍后重试', type: 'error'})
              dialogs.alert.show = true;
              dialogs.alert.message = '数据请求异常，请稍后重试'
              dialogs.alert.code =  -1;
            });
      })
      .catch(() => {
        ElMessage({message: '已取消提交开票信息', type: 'info'})
      }).finally(() => {
        scrollTo(0, 0);
  })

}

const form = reactive({
  invoice_name: '',
  total: '',
  apply: '',
  tax_rate:null,
  audit: '',
  applicant_comments: '',
  applicant: '',
  has_contract: undefined,
  type: 0,
  invoice_no: '',
  invoice_build_time: '',
  invoice_type: -1,
  no_contract_reason: '',
  start_proc: -1,
  project_name: ''
})

watch(() => form.type, newType => {
  if (newType == -1){
    dialogs.redInvoiceTip = true;
  }
})

const copyApplyInfo = (page) => {
  form.invoice_name = page.invoice_name;
  form.total = page.total;
  form.apply = page.apply;
  form.audit = page.audit;
  form.applicant_comments = page.applicant_comments;
  form.has_contract = page.has_contract;
  form.invoice_build_time = page.invoice_build_time;
  form.invoice_no = page.invoice_no;
  form.type = page.type;
  ElNotification({
    title: '',
    message: '数据已复制',
  })
}


</script>

<style >
*{
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.login-bg{
  background: #efefef;
}

#main-table tr:first-child > th:nth-child(3),
#main-table tr:nth-child(2) > th:nth-child(3),
#main-table tr:nth-child(2) > th:nth-child(4),
#main-table tr:nth-child(2) > th:nth-child(5),
#main-table tr:nth-child(2) > th:nth-child(6),
#main-table tr:nth-child(2) > th:nth-child(7){
  background-color: rgb(30, 159, 255) !important;
  color: #ddd;
}


#red-table tr:first-child > th:nth-child(3),
#red-table tr:first-child > th:nth-child(4),
#red-table tr:nth-child(2) > th:nth-child(3),
#red-table tr:nth-child(2) > th:nth-child(4),
#red-table tr:nth-child(2) > th:nth-child(5),
#red-table tr:nth-child(2) > th:nth-child(6),
#red-table tr:nth-child(2) > th:nth-child(7){
  background-color: #f56c6c !important;
  color: #fff;
}
#applyData tr:first-child > th:nth-child(3){
  background-color: #fff !important;
  color: #909399;
}
@media screen and (max-width: 630px) {
  #main-table tr:first-child > th:nth-child(3){
    background-color: #fff !important;
    color: #909399;
  }
  #main-table  tbody > tr > td:nth-child(4),
  #main-table tbody > tr > td:nth-child(9),
  #main-table tr:first-child > th:nth-child(4),
  #main-table tr:nth-child(2) > th:nth-child(8){
    border-left: none !important;
  }
  #main-table tbody > tr:last-child > td:nth-child(4),
  #main-table tbody > tr:last-child > td:nth-child(5),
  #main-table tbody > tr:last-child > td:nth-child(6),
  #main-table tbody > tr:last-child > td:nth-child(7),
  #main-table tbody > tr:last-child > td:nth-child(8){
    border-bottom: 0px !important;
  }



  #red-table tr:first-child > th:nth-child(3){
    background-color: #fff !important;
    color: #909399;
  }
  #red-table  tbody > tr > td:nth-child(4),
  #red-table tbody > tr > td:nth-child(9),
  #red-table tr:first-child > th:nth-child(4),
  #red-table tr:nth-child(2) > th:nth-child(8){
    border-left: none !important;
  }
  #red-table tbody > tr:last-child > td:nth-child(4),
  #red-table tbody > tr:last-child > td:nth-child(5),
  #red-table tbody > tr:last-child > td:nth-child(6),
  #red-table tbody > tr:last-child > td:nth-child(7),
  #red-table tbody > tr:last-child > td:nth-child(8){
    border-bottom: 0px !important;
  }


}
#main-table tbody > tr > td:nth-child(4),
#main-table tbody > tr > td:nth-child(9),
#main-table tr:first-child > th:nth-child(4),
#main-table tr:nth-child(2) > th:nth-child(8){
  border-left: 1px solid rgb(30, 159, 255) ;
}
#main-table tbody > tr:last-child > td:nth-child(4),
#main-table tbody > tr:last-child > td:nth-child(5),
#main-table tbody > tr:last-child > td:nth-child(6),
#main-table tbody > tr:last-child > td:nth-child(7),
#main-table tbody > tr:last-child > td:nth-child(8){
  border-bottom: 2px solid rgb(30, 159, 255) ;
}



#red-table tbody > tr > td:nth-child(4),
#red-table tbody > tr > td:nth-child(9),
#red-table tr:first-child > th:nth-child(4),
#red-table tr:first-child > th:nth-child(5),
#red-table tr:nth-child(2) > th:nth-child(8){
  border-left: 1px solid #f56c6c ;
}
#red-table tbody > tr:last-child > td:nth-child(4),
#red-table tbody > tr:last-child > td:nth-child(5),
#red-table tbody > tr:last-child > td:nth-child(6),
#red-table tbody > tr:last-child > td:nth-child(7),
#red-table tbody > tr:last-child > td:nth-child(8){
  border-bottom: 2px solid #f56c6c ;
}

.el-tabs__active-bar{
  visibility: hidden !important;
}

.red-invoice-shadow{
  -webkit-box-shadow: inset 5px 5px 51px -25px rgba(245,108,108,1);
  -moz-box-shadow: inset 5px 5px 51px -25px rgba(245,108,108,1);
  box-shadow: inset 5px 5px 51px -25px rgba(245,108,108,1);
}
.h-100{
  height: 100%;
}
</style>
